//
//
//
//
//

import BigNumber from "bignumber.js";

import { getPosPoolContract, Drip } from "../utils/cfx";
import { getCfxByVote, getApy } from "../utils";
export default {
  data() {
    return {
      refreshHours: "",
      refreshMin: "",
    };
  },
  mounted() {
    const that = this;
    setInterval(async function () {
      var refreshHours = new Date().getHours();
      var refreshMin = new Date().getMinutes();
      var refreshSec = new Date().getSeconds();
 
      if (+refreshHours == 23 && +refreshMin == 40 && +refreshSec == 0) {
        that.init(); // 指定每天晚上做的事情
      }
    }, 1000);

    setInterval(async function () {
      //var refreshHours = new Date().getHours();
      var refreshMin = new Date().getMinutes();
      var refreshSec = new Date().getSeconds();

      if (+refreshMin === 1 && +refreshSec == 1) {
        that.rate();
      }
    }, 1000);
  },
  methods: {
    toConvert(size) {
      if (!size) return 0;

      var num = 1000.0; //byte

      if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + "K"; //kb
      if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
      if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
      return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
    },
    async init() {
      //const accountAddress = localStorage.getItem("_address"); //window._address; //登录用户 address
      //window._address = accountAddress;

      this.posPoolContract = getPosPoolContract(
        POSPOOL_ADDRESS
      );

      const proArr2 = [];
      proArr2.push(this.posPoolContract.poolSummary());
      proArr2.push(this.posPoolContract.poolAPY());
      proArr2.push(this.posPoolContract.poolName());
      const data2 = await Promise.all(proArr2);
      const poolSummary = data2[0];

      const statistical = [];
      const acfxholders = +poolSummary[3];
      statistical.push(acfxholders);

      const acfxsum = this.toConvert(
        parseFloat(Drip(+poolSummary[4]).toCFX()).toFixed(2)
      );
      statistical.push(acfxsum);

      const setLockedCfx = this.toConvert(getCfxByVote(poolSummary[0]));
      statistical.push(setLockedCfx);

      const setApy = getApy(data2[1]);
      statistical.push(setApy);
      console.log(statistical);

      // 汇率
      this.conflux = new this.$conflux({
        networkId: 1029,
        logger: console,
      });
      this.conflux.provider = window.conflux;

      const EXCHANG_V2 = await this.conflux.Contract({
        abi: EXCHANG_ABI,
        address: EXCHANG_ADDRESS,
      });

      console.log(EXCHANG_V2);

      // 汇率
      const proArr = [];
      const rate = await EXCHANG_V2.estimate();
      const t0 = Drip(+rate[0]).toCFX();
      const rate0 = parseFloat(t0).toFixed(2);
      console.log(rate0);
      proArr.push(rate0);
      const t1 = Drip(+rate[1]).toCFX();
      const rate1 = parseFloat(t1).toFixed(2);
      console.log(rate1);
      proArr.push(rate1);
      const t2 = Drip(+rate[2]).toCFX();
      const rate2 = parseFloat(t2).toFixed(2);
      console.log(rate2);
      proArr.push(rate2);

      this.$axios
        .put(this.$store.state.api + "/statistics", {
          data: statistical,
          rate: proArr,
        })
        .then((response) => {
          console.log(response.data);
        });
    },

    async rate() {
      // 汇率
      this.conflux = new this.$conflux({
        networkId: 1029,
        logger: console,
      });
      this.conflux.provider = window.conflux;

      const EXCHANG_V2 = await this.conflux.Contract({
        abi: EXCHANG_ABI,
        address: EXCHANG_ADDRESS,
      });
      // 汇率
      const rate = await EXCHANG_V2.estimate();
      const t0 = Drip(+rate[0]).toCFX();
      const rate0 = parseFloat(t0).toFixed(2);

      const t1 = Drip(+rate[1]).toCFX();
      const rate1 = parseFloat(t1).toFixed(2);

      const t2 = Drip(+rate[2]).toCFX();
      const rate2 = parseFloat(t2).toFixed(2);

      this.$axios
        .put(this.$store.state.api + "/statistics/rate", {
          val1: rate0,
          val2: rate1,
          val3: rate2,
        })
        .then((response) => {
          console.log(response.data);
        });
    },
  },
};
